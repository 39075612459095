<script setup>

import { onMounted, ref } from "vue"
import { useEARequestStore } from "@/store/ea-request"
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast"

const eaRequestStore = useEARequestStore()
const route = useRoute()
const toast = useToast()

const eaRequest = ref({
  member: {}
})
const loading = ref(false)

const optionStatus = [
	{ name: "Pending", value: 'pending' },
	{ name: "Accept", value: 'accepted' },
	{ name: "Reject", value: 'rejected' }
]

const btnSave = () => {
  loading.value = true

  eaRequestStore.update(
    eaRequest.value.id,
    {
      files: eaRequest.value.files,
      note: eaRequest.value.note,
      status: eaRequest.value.status
    }
  )
    .then((response) => {
      if (response.data.error) {
        toast.add({severity:'warn', summary: 'Warning', detail: response.data.error.message, life: 3000})
        return
      }
      
      toast.add({severity:'success', summary: 'Success', detail: `Success.`, life: 3000})
    })
    .catch((error) => {
      toast.add({severity:'error', summary: 'Error (Catch)', detail: error.message, life: 3000})
    })
    .finally(() => {
      loading.value = false
    })
}

const uploader = (event) => {
  loading.value = true

  eaRequestStore.uploadEx4(route.params.id, event.files[event.files.length - 1])
    .then((response) => {
      if (response.data.error) {
        toast.add({severity:'warn', summary: 'Warning', detail: response.data.error.message, life: 3000})
        return
      }
      
      toast.add({severity:'success', summary: 'Success', detail: `Success.`, life: 3000})

      eaRequest.value.files = response.data.ea_request.files
    })
    .catch((error) => {
      toast.add({severity:'error', summary: 'Error (Catch)', detail: error.message, life: 3000})
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  loading.value = true
  eaRequestStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    eaRequest.value = { ...data.ea_request }

    loading.value = false
  })
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span class="block-title">
          <div>Show #{{ eaRequest.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex flex-wrap">
        <div class="col-12 sm:col-6">
          <div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
            <label class="block text-900 font-medium mb-2" for="provider">Provider</label>
            <InputText v-model="eaRequest.provider" id="provider" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="account_ea">Account</label>
            <InputText v-model="eaRequest.account_ea" id="account_ea" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="platform">Platform</label>
            <InputText v-model="eaRequest.platform" id="platform" type="text" class="w-full mb-3" readonly />

            <Divider />

            <label class="block text-900 font-medium mb-2" for="status">Status</label>
            <Dropdown 
              v-model="eaRequest.status"

              class="w-full mb-3"
              :disabled="loading"
              optionLabel="name"
              :options="optionStatus"
              optionValue="value"
            />

            <label class="block text-900 font-medium mb-2" for="note">Note</label>
            <Textarea v-model="eaRequest.note" id="note" rows="5" class="w-full mb-3" :readonly="loading" />

            <label class="block text-900 font-medium mb-2">File Upload</label>
            <Chips v-model="eaRequest.files" readonly>
              <template #chip="slotProps">
                <div>{{ slotProps.value }}</div>
              </template>
            </Chips>
            <FileUpload
							accept="*.ex4"
							:auto="true"
              chooseLabel="Browse and Upload"
              class="w-full"
							:customUpload="true"
              :disabled="loading"
              mode="basic"
							:showCancelButton="false"
							:showUploadButton="false"

							@uploader="uploader"
						/>

            <br/><br/><br/>

            <Button label="Save" class="w-full" :loading="loading" @click="btnSave" />
          </div>
        </div>

        <div class="col-12 sm:col-6">
          <div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
            <div class="text-center mb-2">
              <img class="border-circle" :src="eaRequest.member.avatar" style="width: 100px;">
            </div>

            <label class="block text-900 font-medium mb-2" for="uid">UID</label>
            <InputText v-model="eaRequest.member.uid" id="uid" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="uhas_uid">Uhas UID</label>
            <InputText v-model="eaRequest.member.uhas_uid" id="uhas_uid" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="first_name">First Name</label>
            <InputText v-model="eaRequest.member.first_name" id="first_name" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="last_name">Last Name</label>
            <InputText v-model="eaRequest.member.last_name" id="last_name" type="text" class="w-full mb-3" readonly />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  ::v-deep(.p-chips ) {
    width: 100%;
    > .p-chips-multiple-container {
      width: 100%;
      > .p-chips-token {
        margin: 5px 0;
        width: 100%;
        > div { width: 100%; }
      }
    }
  }
</style>
