import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useEARequestStore = defineStore('eaRequest', {
  actions: {
		index: ({ filter, page = 1, status }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/ea-requests?page=${page}${ filter ? '&filter=' + filter : '' }${ status ? '&status=' + status : '' }`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/ea-requests/${id}`),
		update: (id, eaRequest) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/ea-requests/${id}`, eaRequest),
		uploadEx4: (id, file) => axios.post(
			`${VUE_APP_ENDPOINT_URL}/api/admin/ea-requests/${id}/upload-ex4`,
			{ file },
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		),
  }
})
